/* eslint-disable */
import * as Types from '../types.generated';

import {
    PlatformFieldsFragment,
    OwnershipFieldsFragment,
    GlobalTagsFieldsFragment,
    GlossaryTermsFragment,
    EntityDomainFragment,
    EntityDataProduct_AccessTokenMetadata_Fragment,
    EntityDataProduct_Assertion_Fragment,
    EntityDataProduct_Chart_Fragment,
    EntityDataProduct_Container_Fragment,
    EntityDataProduct_CorpGroup_Fragment,
    EntityDataProduct_CorpUser_Fragment,
    EntityDataProduct_Dashboard_Fragment,
    EntityDataProduct_DataFlow_Fragment,
    EntityDataProduct_DataHubPolicy_Fragment,
    EntityDataProduct_DataHubRole_Fragment,
    EntityDataProduct_DataHubView_Fragment,
    EntityDataProduct_DataJob_Fragment,
    EntityDataProduct_DataPlatform_Fragment,
    EntityDataProduct_DataPlatformInstance_Fragment,
    EntityDataProduct_DataProcessInstance_Fragment,
    EntityDataProduct_DataProduct_Fragment,
    EntityDataProduct_Dataset_Fragment,
    EntityDataProduct_Domain_Fragment,
    EntityDataProduct_GlossaryNode_Fragment,
    EntityDataProduct_GlossaryTerm_Fragment,
    EntityDataProduct_MlFeature_Fragment,
    EntityDataProduct_MlFeatureTable_Fragment,
    EntityDataProduct_MlModel_Fragment,
    EntityDataProduct_MlModelGroup_Fragment,
    EntityDataProduct_MlPrimaryKey_Fragment,
    EntityDataProduct_Notebook_Fragment,
    EntityDataProduct_OwnershipTypeEntity_Fragment,
    EntityDataProduct_Post_Fragment,
    EntityDataProduct_QueryEntity_Fragment,
    EntityDataProduct_Role_Fragment,
    EntityDataProduct_SchemaFieldEntity_Fragment,
    EntityDataProduct_Tag_Fragment,
    EntityDataProduct_Test_Fragment,
    EntityDataProduct_VersionedDataset_Fragment,
    NonRecursiveDataFlowFieldsFragment,
    InstitutionalMemoryFieldsFragment,
    DeprecationFieldsFragment,
    EmbedFieldsFragment,
    DataPlatformInstanceFieldsFragment,
    ParentContainersFieldsFragment,
    BrowsePathV2FieldsFragment,
    InputFieldsFieldsFragment,
    EntityContainerFragment,
    ParentNodesFieldsFragment,
    GlossaryNodeFragment,
    NonRecursiveMlFeatureTableFragment,
    NonRecursiveMlFeatureFragment,
    NonRecursiveMlPrimaryKeyFragment,
    SchemaMetadataFieldsFragment,
    ParentDomainsFieldsFragment,
    DomainEntitiesFieldsFragment,
    NonConflictingPlatformFieldsFragment,
    EntityDisplayNameFields_AccessTokenMetadata_Fragment,
    EntityDisplayNameFields_Assertion_Fragment,
    EntityDisplayNameFields_Chart_Fragment,
    EntityDisplayNameFields_Container_Fragment,
    EntityDisplayNameFields_CorpGroup_Fragment,
    EntityDisplayNameFields_CorpUser_Fragment,
    EntityDisplayNameFields_Dashboard_Fragment,
    EntityDisplayNameFields_DataFlow_Fragment,
    EntityDisplayNameFields_DataHubPolicy_Fragment,
    EntityDisplayNameFields_DataHubRole_Fragment,
    EntityDisplayNameFields_DataHubView_Fragment,
    EntityDisplayNameFields_DataJob_Fragment,
    EntityDisplayNameFields_DataPlatform_Fragment,
    EntityDisplayNameFields_DataPlatformInstance_Fragment,
    EntityDisplayNameFields_DataProcessInstance_Fragment,
    EntityDisplayNameFields_DataProduct_Fragment,
    EntityDisplayNameFields_Dataset_Fragment,
    EntityDisplayNameFields_Domain_Fragment,
    EntityDisplayNameFields_GlossaryNode_Fragment,
    EntityDisplayNameFields_GlossaryTerm_Fragment,
    EntityDisplayNameFields_MlFeature_Fragment,
    EntityDisplayNameFields_MlFeatureTable_Fragment,
    EntityDisplayNameFields_MlModel_Fragment,
    EntityDisplayNameFields_MlModelGroup_Fragment,
    EntityDisplayNameFields_MlPrimaryKey_Fragment,
    EntityDisplayNameFields_Notebook_Fragment,
    EntityDisplayNameFields_OwnershipTypeEntity_Fragment,
    EntityDisplayNameFields_Post_Fragment,
    EntityDisplayNameFields_QueryEntity_Fragment,
    EntityDisplayNameFields_Role_Fragment,
    EntityDisplayNameFields_SchemaFieldEntity_Fragment,
    EntityDisplayNameFields_Tag_Fragment,
    EntityDisplayNameFields_Test_Fragment,
    EntityDisplayNameFields_VersionedDataset_Fragment,
} from './fragments.generated';
import { PartialLineageResultsFragment } from './lineage.generated';
import { AssertionDetailsFragment, AssertionRunEventDetailsFragment } from './assertion.generated';
import { gql } from '@apollo/client';
import {
    PlatformFieldsFragmentDoc,
    OwnershipFieldsFragmentDoc,
    GlobalTagsFieldsFragmentDoc,
    GlossaryTermsFragmentDoc,
    EntityDomainFragmentDoc,
    EntityDataProductFragmentDoc,
    NonRecursiveDataFlowFieldsFragmentDoc,
    InstitutionalMemoryFieldsFragmentDoc,
    DeprecationFieldsFragmentDoc,
    EmbedFieldsFragmentDoc,
    DataPlatformInstanceFieldsFragmentDoc,
    ParentContainersFieldsFragmentDoc,
    BrowsePathV2FieldsFragmentDoc,
    InputFieldsFieldsFragmentDoc,
    EntityContainerFragmentDoc,
    ParentNodesFieldsFragmentDoc,
    GlossaryNodeFragmentDoc,
    NonRecursiveMlFeatureTableFragmentDoc,
    NonRecursiveMlFeatureFragmentDoc,
    NonRecursiveMlPrimaryKeyFragmentDoc,
    SchemaMetadataFieldsFragmentDoc,
    ParentDomainsFieldsFragmentDoc,
    DomainEntitiesFieldsFragmentDoc,
    NonConflictingPlatformFieldsFragmentDoc,
    EntityDisplayNameFieldsFragmentDoc,
} from './fragments.generated';
import { PartialLineageResultsFragmentDoc } from './lineage.generated';
import { AssertionDetailsFragmentDoc, AssertionRunEventDetailsFragmentDoc } from './assertion.generated';
import * as Apollo from '@apollo/client';
export type GetContainerQueryVariables = Types.Exact<{
    urn: Types.Scalars['String'];
}>;

export type GetContainerQuery = { __typename?: 'Query' } & {
    container?: Types.Maybe<
        { __typename?: 'Container' } & Pick<Types.Container, 'urn' | 'exists' | 'lastIngested'> & {
                platform: { __typename?: 'DataPlatform' } & PlatformFieldsFragment;
                properties?: Types.Maybe<
                    { __typename?: 'ContainerProperties' } & Pick<Types.ContainerProperties, 'name' | 'description'> & {
                            customProperties?: Types.Maybe<
                                Array<
                                    { __typename?: 'CustomPropertiesEntry' } & Pick<
                                        Types.CustomPropertiesEntry,
                                        'key' | 'value'
                                    >
                                >
                            >;
                        }
                >;
                upstream?: Types.Maybe<{ __typename?: 'EntityLineageResult' } & PartialLineageResultsFragment>;
                downstream?: Types.Maybe<{ __typename?: 'EntityLineageResult' } & PartialLineageResultsFragment>;
                editableProperties?: Types.Maybe<
                    { __typename?: 'ContainerEditableProperties' } & Pick<
                        Types.ContainerEditableProperties,
                        'description'
                    >
                >;
                ownership?: Types.Maybe<{ __typename?: 'Ownership' } & OwnershipFieldsFragment>;
                tags?: Types.Maybe<{ __typename?: 'GlobalTags' } & GlobalTagsFieldsFragment>;
                institutionalMemory?: Types.Maybe<
                    { __typename?: 'InstitutionalMemory' } & InstitutionalMemoryFieldsFragment
                >;
                glossaryTerms?: Types.Maybe<{ __typename?: 'GlossaryTerms' } & GlossaryTermsFragment>;
                subTypes?: Types.Maybe<{ __typename?: 'SubTypes' } & Pick<Types.SubTypes, 'typeNames'>>;
                entities?: Types.Maybe<{ __typename?: 'SearchResults' } & Pick<Types.SearchResults, 'total'>>;
                container?: Types.Maybe<{ __typename?: 'Container' } & EntityContainerFragment>;
                health?: Types.Maybe<
                    Array<{ __typename?: 'Health' } & Pick<Types.Health, 'type' | 'status' | 'message' | 'causes'>>
                >;
                assertions?: Types.Maybe<
                    { __typename?: 'EntityAssertionsResult' } & Pick<Types.EntityAssertionsResult, 'total'>
                >;
                parentContainers?: Types.Maybe<
                    { __typename?: 'ParentContainersResult' } & ParentContainersFieldsFragment
                >;
                domain?: Types.Maybe<{ __typename?: 'DomainAssociation' } & EntityDomainFragment>;
                deprecation?: Types.Maybe<{ __typename?: 'Deprecation' } & DeprecationFieldsFragment>;
                dataPlatformInstance?: Types.Maybe<
                    { __typename?: 'DataPlatformInstance' } & DataPlatformInstanceFieldsFragment
                >;
                status?: Types.Maybe<{ __typename?: 'Status' } & Pick<Types.Status, 'removed'>>;
            } & EntityDataProduct_Container_Fragment
    >;
};

export type GetContainerAssertionsQueryVariables = Types.Exact<{
    urn: Types.Scalars['String'];
}>;

export type GetContainerAssertionsQuery = { __typename?: 'Query' } & {
    container?: Types.Maybe<
        { __typename?: 'Container' } & {
            assertions?: Types.Maybe<
                { __typename?: 'EntityAssertionsResult' } & Pick<
                    Types.EntityAssertionsResult,
                    'start' | 'count' | 'total'
                > & {
                        assertions: Array<
                            { __typename?: 'Assertion' } & {
                                runEvents?: Types.Maybe<
                                    { __typename?: 'AssertionRunEventsResult' } & Pick<
                                        Types.AssertionRunEventsResult,
                                        'total' | 'failed' | 'succeeded'
                                    > & {
                                            runEvents: Array<
                                                { __typename?: 'AssertionRunEvent' } & AssertionRunEventDetailsFragment
                                            >;
                                        }
                                >;
                            } & AssertionDetailsFragment
                        >;
                    }
            >;
        }
    >;
};

export const GetContainerDocument = gql`
    query getContainer($urn: String!) {
        container(urn: $urn) {
            urn
            exists
            lastIngested
            platform {
                ...platformFields
            }
            properties {
                name
                description
                customProperties {
                    key
                    value
                }
            }
            upstream: lineage(input: { direction: UPSTREAM, start: 0, count: 100 }) {
                ...partialLineageResults
            }
            downstream: lineage(input: { direction: DOWNSTREAM, start: 0, count: 100 }) {
                ...partialLineageResults
            }
            editableProperties {
                description
            }
            ownership {
                ...ownershipFields
            }
            tags {
                ...globalTagsFields
            }
            institutionalMemory {
                ...institutionalMemoryFields
            }
            glossaryTerms {
                ...glossaryTerms
            }
            subTypes {
                typeNames
            }
            entities(input: { start: 0, count: 1 }) {
                total
            }
            container {
                ...entityContainer
            }
            health {
                type
                status
                message
                causes
            }
            assertions(start: 0, count: 1) {
                total
            }
            parentContainers {
                ...parentContainersFields
            }
            domain {
                ...entityDomain
            }
            ...entityDataProduct
            deprecation {
                ...deprecationFields
            }
            dataPlatformInstance {
                ...dataPlatformInstanceFields
            }
            status {
                removed
            }
        }
    }
    ${PlatformFieldsFragmentDoc}
    ${PartialLineageResultsFragmentDoc}
    ${OwnershipFieldsFragmentDoc}
    ${GlobalTagsFieldsFragmentDoc}
    ${InstitutionalMemoryFieldsFragmentDoc}
    ${GlossaryTermsFragmentDoc}
    ${EntityContainerFragmentDoc}
    ${ParentContainersFieldsFragmentDoc}
    ${EntityDomainFragmentDoc}
    ${EntityDataProductFragmentDoc}
    ${DeprecationFieldsFragmentDoc}
    ${DataPlatformInstanceFieldsFragmentDoc}
`;

/**
 * __useGetContainerQuery__
 *
 * To run a query within a React component, call `useGetContainerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContainerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContainerQuery({
 *   variables: {
 *      urn: // value for 'urn'
 *   },
 * });
 */
export function useGetContainerQuery(
    baseOptions: Apollo.QueryHookOptions<GetContainerQuery, GetContainerQueryVariables>,
) {
    return Apollo.useQuery<GetContainerQuery, GetContainerQueryVariables>(GetContainerDocument, baseOptions);
}
export function useGetContainerLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GetContainerQuery, GetContainerQueryVariables>,
) {
    return Apollo.useLazyQuery<GetContainerQuery, GetContainerQueryVariables>(GetContainerDocument, baseOptions);
}
export type GetContainerQueryHookResult = ReturnType<typeof useGetContainerQuery>;
export type GetContainerLazyQueryHookResult = ReturnType<typeof useGetContainerLazyQuery>;
export type GetContainerQueryResult = Apollo.QueryResult<GetContainerQuery, GetContainerQueryVariables>;
export const GetContainerAssertionsDocument = gql`
    query getContainerAssertions($urn: String!) {
        container(urn: $urn) {
            assertions(start: 0, count: 1000) {
                start
                count
                total
                assertions {
                    ...assertionDetails
                    runEvents(status: COMPLETE, limit: 1) {
                        total
                        failed
                        succeeded
                        runEvents {
                            ...assertionRunEventDetails
                        }
                    }
                }
            }
        }
    }
    ${AssertionDetailsFragmentDoc}
    ${AssertionRunEventDetailsFragmentDoc}
`;

/**
 * __useGetContainerAssertionsQuery__
 *
 * To run a query within a React component, call `useGetContainerAssertionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContainerAssertionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContainerAssertionsQuery({
 *   variables: {
 *      urn: // value for 'urn'
 *   },
 * });
 */
export function useGetContainerAssertionsQuery(
    baseOptions: Apollo.QueryHookOptions<GetContainerAssertionsQuery, GetContainerAssertionsQueryVariables>,
) {
    return Apollo.useQuery<GetContainerAssertionsQuery, GetContainerAssertionsQueryVariables>(
        GetContainerAssertionsDocument,
        baseOptions,
    );
}
export function useGetContainerAssertionsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GetContainerAssertionsQuery, GetContainerAssertionsQueryVariables>,
) {
    return Apollo.useLazyQuery<GetContainerAssertionsQuery, GetContainerAssertionsQueryVariables>(
        GetContainerAssertionsDocument,
        baseOptions,
    );
}
export type GetContainerAssertionsQueryHookResult = ReturnType<typeof useGetContainerAssertionsQuery>;
export type GetContainerAssertionsLazyQueryHookResult = ReturnType<typeof useGetContainerAssertionsLazyQuery>;
export type GetContainerAssertionsQueryResult = Apollo.QueryResult<
    GetContainerAssertionsQuery,
    GetContainerAssertionsQueryVariables
>;
